import api from "@/helpers/api";
import { ref } from "vue";

const useContactForm = () => {
  const email = ref("");
  const name = ref("");
  const message = ref("");
  const sending = ref(false);
  const statusMessage = ref("");

  async function submit() {
    sending.value = true;

    try {
      const contactUsPayload = {
        email: email.value,
        message: message.value,
        name: name.value,
      };

      const {
        data: {
          data: { message: returnMessage, status },
        },
      } = await api.post("v1/contact-us", contactUsPayload);
      statusMessage.value = returnMessage;

      if (status) {
        email.value = "";
        message.value = "";
        name.value = "";
      }
    } catch (error) {
      statusMessage.value =
        "Erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.";

      console.error(error);
    } finally {
      sending.value = false;

      setTimeout(() => (statusMessage.value = ""), 6000);
    }
  }

  return {
    email,
    message,
    name,
    sending,
    statusMessage,
    submit,
  };
};

export default useContactForm;
