<template>
  <Modal
    class="o-about-modal"
    id="about-modal"
    modalClass="pt-14 w-3/4 h-4/5 xl:pt-8"
  >
    <div class="h-full">
      <div class="mt-12">
        <div class="mb-5">
          <img class="mx-auto my-0 w-56" :src="companyLogo" />
        </div>
        <div
          class="text-center w-[80%] lg:w-[92%] mx-auto my-0 text-base text-[#5A5A5A] lg:h-[32vh] lg:px-24"
        >
          <p class="mb-4">
            Pensando em proporcionar mais praticidade aos seus consumidores, a
            Yuny preparou esse espaço interativo para que os proprietários
            possam customizar suas unidades de maneira prática e ilustrada.
          </p>
          <p>
            Através de uma curadoria arquitetônica pré-selecionada, nossa equipe
            oferece uma série de itens customizáveis para diversos ambientes das
            unidades, com possibilidade de visualização e entendimento de custo
            em tempo real.
          </p>
        </div>
        <div class="sobre-slider w-full px-6 absolute bottom-8 left-0">
          <div
            class="text-center uppercase font-thin text-sm tracking-[6px] text-bg-skin-color1 mt-4 mb-2"
          >
            Aqui Você Pode:
          </div>
          <div>
            <div
              class="absolute w-full top-0 left-0 h-full z-30 overflow-visible pointer-events-none"
            >
              <button
                id="sobre-slider-anterior"
                class="left-0 w-[32px] h-[42px] block absolute top-[60%] translate-y-1/2 bg-skin-color4 pointer-events-auto shadow"
                type="button"
              >
                <Image
                  class="mx-auto my-0 mb-2 mt-2"
                  src="images/sobre-a-plataforma/seta.svg"
                />
              </button>
              <button
                id="sobre-slider-proximo"
                class="right-0 w-[32px] h-[42px] block absolute top-[60%] translate-y-1/2 bg-skin-color4 pointer-events-auto shadow"
                type="button"
              >
                <Image
                  class="mx-auto my-0 mb-2 mt-2 rotate-180"
                  src="images/sobre-a-plataforma/seta.svg"
                />
              </button>
            </div>
            <div class="w-[94%] 2xl:w-full mx-auto my-0" id="sobre-slider">
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/definir.svg"
                />
                <p class="text-xs text-[#737372]">
                  Definir detalhes de<br />
                  seu apartamento
                </p>
              </Card>
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/escolher.svg"
                />
                <p class="text-xs text-[#737372]">
                  Escolher a opção<br />
                  de planta ideal
                </p>
              </Card>
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/customizar.svg"
                />
                <p class="text-xs text-[#737372]">
                  Customizar diversos<br />
                  itens em cada<br />
                  ambiente
                </p>
              </Card>
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/canal.svg"
                />
                <p class="text-xs text-[#737372]">
                  Ter um canal de<br />
                  comunicação com<br />
                  especialistas
                </p>
              </Card>
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/definir2.svg"
                />
                <p class="text-xs text-[#737372]">
                  Definir itens<br />
                  de infraestrutura
                </p>
              </Card>
              <Card>
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/controlar.svg"
                />
                <p class="text-xs text-[#737372]">
                  Controlar os<br />
                  valores gastos
                </p>
              </Card>
              <div
                class="bg-white rounded-md shadow-lg w-36 pt-6 text-center min-h-[16vh] lg:min-h-[20vh]"
              >
                <Image
                  class="mx-auto my-0 h-12 mb-2"
                  src="images/sobre-a-plataforma/fechar.svg"
                />
                <p class="text-xs text-[#737372]">
                  Fechar e enviar<br />
                  o pedido
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Image from "@/components/atoms/Image.vue";
import Modal from "@/components/molecules/Modal.vue";
import useGetVentureProp from "@/composables/useGetVentureProp";
import { useCloseModal } from "@/composables/useHandleModals";
import { inject, onMounted, provide } from "vue";
import Card from "../atoms/Card.vue";

export default {
  name: "AboutModal",
  components: { Image, Modal, Card },
  setup() {
    const { setAboutModalOpen } = inject("aboutModalOpen");
    const { closeModal } = useCloseModal(setAboutModalOpen);
    const { companyLogo } = useGetVentureProp("companyLogo");
    const $ = window.$;

    function initSliderAndScroll() {
      $("#sobre-slider").slick({
        slidesToShow: 6,
        infinite: false,
        mobileFirst: false,
        nextArrow: $("#sobre-slider-proximo"),
        prevArrow: $("#sobre-slider-anterior"),
        respondTo: "min",
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      });

      $(".scrollbar-dynamic").scrollbar();
    }

    onMounted(initSliderAndScroll);

    provide("closeModal", closeModal);

    return { companyLogo };
  },
};
</script>
